import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from '../../../../infrastructure/auth/user.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input()
  entityWithAvatar: any;

  @Input()
  size = 'sm';

  @Output()
  avatarUpdated = new EventEmitter<string | undefined>();

  imageSrc!: string;

  constructor() { }

  ngOnInit(): void {
  }

  uploadAvatar(e: any): void {
    if (!this.entityWithAvatar) {
      return;
    }

    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any): void {
    const reader = e.target;
    this.imageSrc = reader.result;

    this.avatarUpdated.emit(this.imageSrc);
  }

  deleteAvatar(): void {
    this.avatarUpdated.emit(undefined);
  }

  getAvatarClassName(size: string): string {
    return `avatar avatar-${size} rounded-circle`;
  }
}
