<div #sidemenu class="sidemenu">
  <nav class="navbar navbar-vertical bg-white navbar-light align-items-start">
    <div class="d-flex flex-column">
      <div>
        <div class="text-center">
          <div
            class="d-flex flex-row align-items-center cursor-pointer text-danger"
            (click)="toggle()"
          >
            <img
              class="logo-icon"
              src="../assets/img/brand/logo-icon-flat.png"
            />
            <img
              class="logo-text ml-3 collapsed-invisible"
              src="../assets/img/brand/logo-text-azul.png"
            />
            <!--            <i class="fa-solid fa-layer-group fa-2xl"></i>-->
            <!--            <h1 class="m-2 collapsed-invisible">LegalManager<strong>Lite</strong></h1>-->
            <i
              class="fa-solid fa-right-from-bracket fa-rotate-180 ml-4 text-default"
            ></i>
          </div>
        </div>
        <!-- Navigation -->
        <ul class="navbar-nav mt-3">
          <li class="nav-item">
            <a
              routerLinkActive="active"
              [matTooltip]="'core.menu.dashboard' | translate"
              routerLink="/dashboard"
              (click)="close()"
              class="nav-link"
            >
              <div><i class="fa-solid fa-house fa-xl"></i></div>
              <h3 class="m-0 ml-3 mt-1 collapsed-invisible">
                {{ "core.menu.dashboard" | translate }}
              </h3>
            </a>
          </li>

          <li class="nav-item">
            <a
              routerLinkActive="active"
              [matTooltip]="'core.menu.tasks' | translate"
              routerLink="/tasks"
              (click)="close()"
              class="nav-link"
            >
              <div><i class="fa-solid fa-list-check"></i></div>
              <h3 class="m-0 ml-3 mt-1 collapsed-invisible">
                {{ "core.menu.tasks" | translate }}
              </h3>
            </a>
          </li>

          <li class="nav-item">
            <a
              routerLinkActive="active"
              [matTooltip]="'core.menu.projects' | translate"
              routerLink="/projects"
              (click)="close()"
              class="nav-link"
            >
              <div><i class="fa-solid fa-scale-balanced"></i></div>
              <h3 class="m-0 ml-3 mt-1 collapsed-invisible">
                {{ "core.menu.projects" | translate }}
              </h3>
            </a>
          </li>

          <li class="nav-item" *ngIf="!isLimited">
            <a routerLinkActive="active" [matTooltip]="'core.menu.finance' | translate" routerLink="/finance" (click)="close()" class="nav-link">
              <div><i class="fa-solid fa-building-columns"></i></div>
              <h3 class="m-0 ml-3 mt-1 collapsed-invisible">{{ 'core.menu.finance' | translate }}</h3>
            </a>
          </li>
        </ul>
        <!-- Divider -->
        <hr class="my-3" *ngIf="isAdmin">
        <ul class="navbar-nav" *ngIf="isAdmin">
          <li class="nav-item">
            <a routerLinkActive="active" [matTooltip]="'core.menu.doc_generator' | translate" routerLink="/doc-generator" (click)="close()" class="nav-link">
              <div><i class="fa-brands fa-creative-commons-share"></i></div>
              <h3 class="m-0 ml-3 collapsed-invisible">{{ 'core.menu.doc_generator' | translate }}</h3>
            </a>
          </li>
        </ul>
        <hr class="my-3">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a routerLinkActive="active" [matTooltip]="'core.menu.customers' | translate" routerLink="/customers" (click)="close()" class="nav-link">
              <div><i class="fa-solid fa-users"></i></div>
              <h3 class="m-0 ml-3 collapsed-invisible">{{ 'core.menu.customers' | translate }}</h3>
            </a>
          </li>
        </ul>
        <!-- Divider -->
        <hr class="my-3" />
        <!-- Heading -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              routerLinkActive="active"
              [matTooltip]="'core.menu.support' | translate"
              routerLink="/support"
              (click)="close()"
              class="nav-link"
            >
              <div><i class="fa-solid fa-circle-question"></i></div>
              <h3 class="m-0 ml-3 collapsed-invisible">
                {{ "core.menu.support" | translate }}
              </h3>
            </a>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <a class="nav-link" href="https://demos.creative-tim.com/argon-dashboard-angular/documentation/alerts">-->
          <!--              <i class="ni ni-ui-04"></i> Components-->
          <!--            </a>-->
          <!--          </li>-->
        </ul>
      </div>
    </div>
  </nav>

  <nav class="navbar navbar-top navbar-expand-md tenant-div" id="navbar-main">
    <ul class="navbar-nav align-items-center d-flex">


      <li class="nav-item">
        <div ngbDropdown placement="bottom-left" #tenantDropdown="ngbDropdown">
          <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <app-avatar size="sm" [entityWithAvatar]="this.userService.loadLoggedUserTenant()?.tenant"></app-avatar>
            </div>
          </a>

          <div class="dropdown-content dropdown-bottom-arrow dropdown-arrow-left p-3 dropdown-activity-adjust" ngbDropdownMenu>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Configurações</h6>
          </div>

          <a
            routerLinkActive="active"
            href="javascript:void(0)"
            class="dropdown-item"
            (click)="showTenantOptions()"
            *ngIf="this.userTenants && this.userTenants.length > 1"
          >
            <i class="ni ni-single-02"></i>
            <span>{{
                "core.user_menu.switch_workspace" | translate
              }}</span>
          </a>

          <a
            routerLinkActive="active"
            [routerLink]="['/settings']"
            class="dropdown-item"
          >
            <i class="ni ni-settings-gear-65"></i>
            <span>{{ "core.user_menu.settings" | translate }}</span>
          </a>
        </div>
        </div>
      </li>
    </ul>
  </nav>
</div>
